import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import * as St from "../../Styled"
import ReadMorePara from "../../ReadMorePara"
import {
  MEDIA_TYPE_VIDEO,
  convertToSlug,
  VIDEO_MODE_MAXIMIZED,
  VIDEO_MODE_MINIMIZED,
  DATA_TYPE_STATION_VIDEO,
} from "../../../constants/props"
import { stationVideoDetailsShareURL } from "../../../constants/share"
import { AppContext } from "../../../contexts/AppContext"
import LikeAndShare from "../../LikeAndShare"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  flex: 1;
  position: relative;
`

const SelectedVideo = ({ data }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { selectedCountry, ctxPlayer } = useContext(AppContext)
  const { id, title, station } = data
  const stationNameSlug = station && station.slug ? station.slug : "all"
  const stationID = station && station.id ? station.id : "all"
  const image =
    data.image && data.image.url
      ? data.image.url.startsWith("http")
        ? data.image.url
        : basePrefix + data.image.url
      : ""
  return (
    <St.VideoFeaturedContainer videoPlaying={true}>
      <St.VideoFeaturedBottomTextSection>
        <St.VideoFeaturedColumnText>
          <St.VideoTitle> {ctxPlayer.title} </St.VideoTitle>
          <St.VideoInfoLabel>
            {ctxPlayer.subtitles && ctxPlayer.subtitles.length
              ? ctxPlayer.subtitles[0] + ctxPlayer.subtitles[1]
              : ""}
          </St.VideoInfoLabel>
          <ReadMorePara string={ctxPlayer.about} offset={50}></ReadMorePara>
        </St.VideoFeaturedColumnText>
        <St.VideoFeaturedColumnText
          style={{ alignItems: "flex-end", flexShrink: 0 }}
        >
          <St.VideoViews>
            <span>
              {ctxPlayer.viewed != null ? ctxPlayer.viewed + " " : "0" + " "}
            </span>
            <span>{ctxPlayer.viewed == 1 ? "View" : "Views"}</span>
          </St.VideoViews>
          <St.VideoIconsSection>
            <LikeShare>
              <LikeAndShare
                shareURL={
                  selectedCountry && selectedCountry.id
                    ? stationVideoDetailsShareURL(
                        id,
                        convertToSlug(title),
                        stationID,
                        stationNameSlug,
                        image
                      )
                    : null
                }
                shareBoxArrange={true}
                id={id}
                likeType={DATA_TYPE_STATION_VIDEO}
                shareID={"selectedVideo_" + id}
              />
            </LikeShare>
          </St.VideoIconsSection>
        </St.VideoFeaturedColumnText>
      </St.VideoFeaturedBottomTextSection>
    </St.VideoFeaturedContainer>
  )
}

const FeaturedBox = ({ video, station }) => {
  const { updatePlayer, ctxPlayer } = useContext(AppContext)
  useEffect(() => {
    updatePlayer({
      ...video,
      type: MEDIA_TYPE_VIDEO,
      preview:
        video.image && video.image.url
          ? video.image.url.startsWith("http")
            ? video.image.url
            : video + video.image.url
          : "",
      playlist:
        station && station.videos && station.videos.length
          ? station.videos
          : [],
      station: station
        ? {
            id: station.id,
            slug: convertToSlug(station.title),
          }
        : null,
      artist: null, // to signify station is owner of this video
      videoMode:
        ctxPlayer.videoMode && ctxPlayer.videoMode === VIDEO_MODE_MINIMIZED
          ? VIDEO_MODE_MINIMIZED
          : VIDEO_MODE_MAXIMIZED, // here by default when user directly lands to this page it will be opened in maximized mode, but
      // to handle case where suer navigates to videos page by click manimized here we need to set previous mode
      viewed: video.viewed,
      dataType: DATA_TYPE_STATION_VIDEO,
      playing: false,
    })
  }, [video.id])

  video = {
    ...video,
    subtitles: [
      video.show ? video.show.title : "",
      `${station.title} • ${moment(video.updatedAt)
        .startOf("seconds")
        .fromNow()}`,
    ],
  }

  return (
    <St.VideoFeaturedBox videoPlaying={true}>
      <SelectedVideo
        data={{
          ...video,
          presenter:
            video.presenter && video.presenter.title
              ? video.presenter.title
              : undefined,
          station
        }}
      />
    </St.VideoFeaturedBox>
  )
}

// Fetches both station basic detail (station name) and featured podcasts that are needed to draw featured podcast box
// and then passed that information to next component to draw

export default FeaturedBox
