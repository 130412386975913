import React, { useState } from "react"
import * as St from "../../Styled"
import VideoCard from "./VideoCard"

const AllVideos = ({ station }) => {
  let [sortCriteria, setSortCriteria] = useState("asc")

  const onSortChange = event => {
    // FIND COUNTRY IN COUNTRIES ARRAY WITH SELECTED ID AND EXPAND IT TO CREATE NEW OBJECT AND SET AS NEW SELECTED COUNTRY
    setSortCriteria(event.target.value)
  }

  let videos = []

  if (station && station.videos && station.videos.length) {
    // here we are assuming that we get videos sorted by their titles already in ascending order from above
    if (sortCriteria === "asc") {
      videos = station.videos
    } else {
      videos = station.videos.reverse()
    }
  }

  return (
    <St.AllCardsSection>
      <St.AllCardsTopSection>
        <St.AllCardsHeading>
          VIDEO ON DEMAND : {station.title}
        </St.AllCardsHeading>
        <St.SortSpan>
          SORT :
          <St.SortSelect defaultValue={sortCriteria} onChange={onSortChange}>
            <St.SortOption value="title:asc">A-Z</St.SortOption>
            <St.SortOption value="title:desc">Z-A</St.SortOption>
          </St.SortSelect>
        </St.SortSpan>
      </St.AllCardsTopSection>
      <St.VideoGridAllWrapper>
        {videos &&
          videos.length &&
          videos.map((video, i) => (
            <VideoCard key={i} video={video} station={station} />
          ))}
      </St.VideoGridAllWrapper>
    </St.AllCardsSection>
  )
}

export default AllVideos
