import React, { useContext } from "react"
import SelectedVideo from "./SelectedVideo"
import AllVideos from "./AllVideos"
import Query from "../Query"
import { VIDEO_DETAILS_BY_ID } from "../../queries/page/video"
import { QUERY_STATION_BY_ID_AND_VIDEOS } from "../../queries/page/station"
import * as St from "../Styled"
import { AppContext } from "../../contexts/AppContext"
import { DEVICE_LANDSCAPE } from "../../constants/breakpoints"
import { VIDEO_MODE_MAXIMIZED } from "../../constants/props"

const ContentVideoDetails = ({ stationID, videoID }) => {
  const { device, ctxPlayer } = useContext(AppContext)
  return (
    <Query
      query={QUERY_STATION_BY_ID_AND_VIDEOS}
      variables={{
        id: stationID,
      }}
      fetchPolicy="network-only"
    >
      {({ data: { station } }) => {
        return (
          <Query
            query={VIDEO_DETAILS_BY_ID}
            variables={{
              id: videoID,
            }}
            fetchPolicy="network-only"
          >
            {({ data: { video } }) => {
              return device !== DEVICE_LANDSCAPE ||
                ctxPlayer.videoMode !== VIDEO_MODE_MAXIMIZED ? (
                <St.ContentParent>
                  <St.AdWrapper>
                    <St.LeftAdSection />
                    <St.LeftAd2Section />
                  </St.AdWrapper>
                  <St.ContentWrapper>
                    <SelectedVideo station={station} video={video} />
                    <St.CenterContentSection>
                      <AllVideos station={station} />
                    </St.CenterContentSection>
                    <St.BottomAdSection />
                  </St.ContentWrapper>
                  <St.AdWrapper>
                    <St.RightAdSection />
                    <St.RightAd2Section />
                  </St.AdWrapper>
                </St.ContentParent>
              ) : null
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default ContentVideoDetails
